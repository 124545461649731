import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Routing from "./components/Routing";
import Navigation from "./components/nav/nav";

// Get the root element from the DOM
const container = document.getElementById("root");

// Create a root using the new createRoot method
const root = createRoot(container);

// Render your application
root.render(
  <React.StrictMode>
    <Navigation />
    <Routing />
  </React.StrictMode>
);

reportWebVitals();
